import { createReducer, on } from '@ngrx/store';
import { login, logout, setUser, setError } from '../actions/auth.actions';
import { User } from '@auth0/auth0-angular';
import { initialAuthState } from 'src/app/core/models/auth.model';

export const authReducer = createReducer(
  initialAuthState,
  on(login, (state, { user, token }) => ({
    ...state,
    user,
    token,
    isAuthenticated: true,
    error: null,
  })),
  on(logout, () => initialAuthState),
  on(setUser, (state, { user }) => ({
    ...state,
    user,
    isAuthenticated: true,
    error: null,
  })),
  on(setError, (state, { error }) => ({
    ...state,
    error,
  }))
);
