import { ActionReducerMap } from '@ngrx/store';
import { authReducer } from './reducers/auth.reducer';
import { languageReducer } from './reducers/language.reducer';
import { roleReducer } from './reducers/role.reducer';
import { AuthState } from '../core/models/auth.model';
import { LanguageState } from 'src/app/core/models/language.model';
import { RolesState } from './reducers/role.reducer';
import { siteReducer,sitesReducer,typeReducer,typesReducer, SiteState ,SitesState , TypeState,TypesState } from './reducers/site.reducer';
import { constantsReducer, ConstantsState } from './reducers/constants.reducer';

export interface AppState {
  auth: AuthState;
  language: LanguageState;
  role:RolesState,
  site:SiteState,
  sites:SitesState,
  typeSite:TypeState,
  typesSite:TypesState,
  constants: ConstantsState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  language: languageReducer,
  role:roleReducer,
  site:siteReducer,
  sites:sitesReducer,
  typeSite:typeReducer,
  typesSite:typesReducer,
  constants: constantsReducer
};
