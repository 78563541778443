import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';
import { reducers } from 'src/app/store/app.store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {  provideEffects } from '@ngrx/effects';
import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from "src/environments/environment";
import { AuthEffects } from './store/effects/auth.effects';
import { RoleEffects } from './store/effects/role.effects';
import { SiteEffects } from './store/effects/site.effects';
import { ConstantsEffects } from './store/effects/constants.effects';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SiteValidationInterceptor } from './core/interceptors/site-validation.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    {
      provide: 'GOOGLE_MAPS_API_KEY',
      useValue: env.googleMapsApiKey
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiteValidationInterceptor,
      multi: true
    },
    provideStore(reducers),
    provideEffects([AuthEffects,RoleEffects,SiteEffects,ConstantsEffects]),
    provideStoreDevtools({ maxAge: 25 }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      GoogleMapsModule,
      AuthModule.forRoot({
        ...env.auth,
        useRefreshTokensFallback: true,
        useRefreshTokens: true,
        cacheLocation: "localstorage",
        authorizationParams: {
          redirect_uri: window.location.origin,
          audience: "https://my-test-api/",
        },
        skipRedirectCallback:
          window.location.pathname === `${env.apiURL}`,
        httpInterceptor: {
          allowedList: [
            "/api",
            "/api/*",
            {
              uri: `https://${env.auth.domain}/*`,
              tokenOptions: {
                authorizationParams: {
                  audience: `https://${env.auth.domain}/`,
                },
              },
            },
          ],
        },
      }),
      NgSelectModule,
      ToastrModule.forRoot(),
      BrowserModule, AppRoutingModule
    ),
    provideAnimations()
  ]
};