// store/reducers/user.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as SiteActions from '../actions/site.actions';
import { Site } from 'src/app/core/models/site.model';

export interface SitesState {
  sites: Site[];
  isLoading: boolean;
  hasError: boolean;
}

const initialState: SitesState = {
  sites: [],
  isLoading: false,
  hasError: false
};
export interface TypeState {
  typeSite: string;
}

const getInitialTypeSite = (): string => {
  const storedType = localStorage.getItem('type');
  if (storedType) {
    try {
      const parsedType = JSON.parse(storedType);
      return parsedType.typeSite || '';
    } catch (error) {
      console.error('Error parsing typeSite from localStorage', error);
      return '';
    }
  }
  return '';
};

const initialStateType: TypeState = {
  typeSite: getInitialTypeSite()
};
export interface TypesState {
  typesSite: any[];
  isLoading: boolean;
  hasError: boolean;
}

const initialStateTypes: TypesState = {
  typesSite: [],
  isLoading: false,
  hasError: false
};

export interface SiteState {
  site: Site;
}

const initialStateSite: SiteState = {
  site: {
    siteId: '',
    code: '',
    name: '',
    parkingType: '',
    createdAt: '',
    updatedAt: '',
    entrancesCount: 0,
    exitsCount: 0,
    long: '',
    lat: '',
    logoUrl: '',
    workingHours: {
      active: false,
      start: null,
      end: null
    },
    gymAreaConfig: {
      hoursPerVisit: null,
      FreeVisitsCount: null
    },
    notifications: {
      gatesActivity: {
        active: false,
        emails: []
      },
      cashierShiftReport: {
        active: false,
        emails: []
      },
      blacklistedCarsReport: {
        active: false,
        emails: []
      }
    },
    registeredServices: {
      public: false,
      vip: false,
      gym: false,
      reserved: false,
      openReserved: false,
      community:false
    },
    blacklistConfig: {
      action: ''
    }
  },
};
export const sitesReducer = createReducer(
  initialState,
  on(SiteActions.loadSitesTypeSuccess, (state, { sites }) => ({
    ...state,
    sites,
  }))
); 
export const siteReducer = createReducer(
  initialStateSite,
  on(SiteActions.loadSiteSuccess, (state, { site }) => ({
    ...state,
    site,
  }))
); 
export const typeReducer = createReducer(
  initialStateType,
  on(SiteActions.loadSiteTypeSuccess, (state, { typeSite }) => ({
    ...state,
    typeSite,
  }))
);
export const typesReducer = createReducer(
  initialStateTypes,
  on(SiteActions.loadSiteTypesSuccess, (state, { typesSite }) => ({
    ...state,
    typesSite,
  }))
);