// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { RoleService } from 'src/app/core/services/APIs/user/role.service';
import { loadRoles, loadRolesSuccess } from '../actions/role.actions';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
@Injectable()
export class RoleEffects {

    constructor(private actions$: Actions,
        private auth: AuthService,
        private store:Store,
        private roleService: RoleService) { }

    loadRoles$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadRoles),
                tap(() => {
                    // Check localStorage first
                    const storedRoles = localStorage.getItem('roles');
                    if (storedRoles) {
                        const roles = JSON.parse(storedRoles).roles;
                        this.store.dispatch(loadRolesSuccess({ roles }));
                        return;
                    }

                    // If no stored roles, fetch from API
                    this.auth.user$.subscribe((user: any) => {
                        this.roleService.getUserRole({ userId: user.sub, orgId: user.org_id })
                            .pipe(
                                map((roles: any) => roles?.data),
                                catchError(() => of({ type: '[User] Load Roles Failed' }))
                            )
                            .subscribe((roles: any) => {
                                this.store.dispatch(loadRolesSuccess({ roles: roles }));
                            })
                    });
                })
            ),
        { dispatch: false }
    );

    persistRole$ = createEffect(
        () =>
          this.actions$.pipe(
            ofType(loadRolesSuccess),
            tap(({roles}) => {
              localStorage.setItem('roles', JSON.stringify({ roles })); 
            })
          ),
        { dispatch: false }
      );
}
