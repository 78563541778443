import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.store';
import { AuthState } from 'src/app/core/models/auth.model';

// Step 1: Base selector to get the `auth` slice of the state
export const selectAuthState = createFeatureSelector<AppState, AuthState>('auth');

// Select user data
export const selectUser = createSelector(
    selectAuthState,
    (authState: AuthState) => authState.user
);

// Select authentication status
export const selectIsAuthenticated = createSelector(
    selectAuthState,
    (authState: AuthState) => authState.isAuthenticated
);

// Select error state
export const selectAuthError = createSelector(
    selectAuthState,
    (authState: AuthState) => authState.error
);

export const selectAuthToken = createSelector(
    selectAuthState,
    (state: AuthState) => state.token
  );
export const selectOrgId = createSelector(
    selectAuthState,
    (state: AuthState) => state.user?.org_id
  );