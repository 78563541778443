import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { GlobalService } from 'src/app/core/services/APIs/dashboard/global.service';
import * as ConstantsActions from '../actions/constants.actions';

@Injectable()
export class ConstantsEffects {
  loadConstants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConstantsActions.loadConstants),
      switchMap(() => {
        // Check if constants exist in localStorage
        const cachedConstants = localStorage.getItem('CONSTANTS');
        if (cachedConstants) {
          try {
            const parsedConstants = JSON.parse(cachedConstants);
            return of(ConstantsActions.loadConstantsSuccess({ constants: parsedConstants }));
          } catch (error) {
            console.error('Error parsing constants from localStorage:', error);
          }
        }

        // If no valid cached data, make the API call
        return this.globalService.getConstants().pipe(
          map((response: any) => {
            const transformedData = response?.extras?.constants;
            return ConstantsActions.loadConstantsSuccess({ constants: transformedData });
          }),
          catchError(error =>
            of(ConstantsActions.loadConstantsFailure({ error }))
          )
        );
      })
    )
  );

  // Effect to store constants in localStorage when successfully loaded
  persistConstants$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConstantsActions.loadConstantsSuccess),
        tap(({ constants }) => {
          try {
            localStorage.setItem('CONSTANTS', JSON.stringify(constants));
          } catch (error) {
            console.error('Error storing constants in localStorage:', error);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private globalService: GlobalService
  ) {}
} 