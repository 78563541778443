import { Component, OnInit, signal, Inject } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { Router, RouterOutlet } from '@angular/router';
import { NgClass } from '@angular/common';
import { ResponsiveHelperComponent } from './shared/components/responsive-helper/responsive-helper.component';
import { NgxSonnerToaster } from 'ngx-sonner';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.store';
import { changeLanguage } from './store/actions/language.actions';
import { AuthService } from '@auth0/auth0-angular';
import { login, logout, setUser } from './store/actions/auth.actions';
import { TranslationService } from './core/services/translation.service';
import { selectCurrentLanguage } from './store/selectors/language.selectors';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgClass, RouterOutlet, ResponsiveHelperComponent, NgxSonnerToaster],
})
export class AppComponent implements OnInit {
  title = 'Limitless Parking';
  dir = signal('ltr');
  lang = signal('en');
  constructor(private auth: AuthService,
    private translationService: TranslationService,
    public themeService: ThemeService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {

    const language = localStorage.getItem('lng');
    const dir = localStorage.getItem('dir');
    if (language && dir) {
      this.dir.set(dir);
      this.lang.set(language);
      this.translate.setDefaultLang(language);
      // Set current language
      this.translate.use(language);
      this.translationService.changeLang(language);
    } else {
      this.dir.set('ltr');
      this.lang.set('en');
      this.translate.setDefaultLang('en');
      // Set current language
      this.translate.use('en');
      this.translationService.changeLang('en');


    }

    this.store.select(selectCurrentLanguage).subscribe(language => {
      if (language) {
        const dir = language === 'ar' ? 'rtl' : 'ltr';
        this.dir.set(dir);
        this.lang.set(language);
      }
    });
  }
  ngOnInit(): void {
    // Check if the user is already authenticated
    const authData = localStorage.getItem('auth');
    if (authData) {
      const { user, token } = JSON.parse(authData);
      if (token) {
        this.store.dispatch(login({ user, token }));
      } else {
        this.store.dispatch(logout());
      }
    } else {
      this.auth.user$.subscribe(user => {
        this.auth.getAccessTokenSilently().subscribe(token => {
          this.store.dispatch(login({ user: user, token: token }))
        })
      })
    }
  }
}
