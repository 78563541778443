import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { isOrganizationApi, extractSiteParam } from '../utils/api.utils';

@Injectable()
export class SiteValidationInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip validation for organization APIs
    if (isOrganizationApi(request.url)) {
      return next.handle(request);
    }

    // Extract site parameter
    const siteId = extractSiteParam(request.url, request.params);

    // Block request if site is 'all'
    if (siteId === 'all') {
      return throwError(() => new HttpErrorResponse({
        error: {
          message: 'Invalid site ID: Operations with site="all" are only allowed for organization APIs'
        },
        status: 400,
        statusText: 'Bad Request'
      }));
    }

    return next.handle(request);
  }
} 