import { createAction, props } from '@ngrx/store';

export const login = createAction('Login', props<{ user: any; token: string }>());
export const logout = createAction('Logout');
export const setUser = createAction(
    '[Auth] Set User',
    props<{ user: any }>()
  );
  export const setError = createAction(
    '[Auth] Set Error',
    props<{ error: string }>()
  );
export const setStatistics = createAction(
  '[Auth] Set Statistics',
  props<{ statistics: any }>()
);