// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@auth0/auth0-angular';
import { login, logout, setError, setUser, setStatistics } from '../actions/auth.actions';
import { of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { GlobalService } from 'src/app/core/services/APIs/dashboard/global.service';
import { RoleService } from 'src/app/core/services/APIs/user/role.service';
import { loadRoles } from '../actions/role.actions';
import { Store } from '@ngrx/store';
import { transformPayload } from 'src/app/core/utils/array-utils';
import { loadSites } from '../actions/site.actions';
import { loadConstants } from '../actions/constants.actions';
@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions,
    private store: Store,
    private auth: AuthService,
    private globalService: GlobalService,) { }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap(() =>
        this.auth.loginWithPopup().pipe(
          tap(() => this.auth.handleRedirectCallback()),
          switchMap(() =>
            this.auth.user$.pipe(
              map((user) => ({
                user,
                token: this.auth.idTokenClaims$.toString(), // Use Auth0's idToken
              }))
            )
          ),
          map(({ user, token }) => login({ user, token })),
          catchError((error) => of(setError({ error: error.message })))
        )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
          localStorage.clear();
          localStorage.removeItem('auth');
        })
      ),
    { dispatch: false }
  );

  persistAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        tap(({ user, token }) => {
          // Check for null user or invalid token
          if (!user || !token) {
            this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
            localStorage.clear();
            return;
          }

          localStorage.setItem('auth', JSON.stringify({ user, token }));
          this.store.dispatch(setUser({ user: user })); 
          this.store.dispatch(loadRoles()); 
          this.store.dispatch(loadSites()); 
          this.store.dispatch(loadConstants());

          // Check localStorage for statistics first
          const storedStats = localStorage.getItem('statistics');
          if (!storedStats) {
            this.globalService.getStatistics().subscribe((res: any) => {
              const transformedStats = transformPayload(res?.data);
              localStorage.setItem('statistics', JSON.stringify(transformedStats));
              this.store.dispatch(setStatistics({ statistics: transformedStats }));
            });
          } else {
            const statistics = JSON.parse(storedStats);
            this.store.dispatch(setStatistics({ statistics }));
          }
        })
      ),
    { dispatch: false }
  );

  // Add new effect to check auth status
  checkAuth$ = createEffect(
    () =>
      this.auth.isAuthenticated$.pipe(
        switchMap((isAuthenticated) => {
          if (!isAuthenticated) {
            // Try to get current user
            return this.auth.user$.pipe(
              switchMap(user => {
                if (user) {
                  // User exists but token might be invalid, try to refresh
                  return this.auth.getAccessTokenSilently().pipe(
                    tap(newToken => {
                      if (newToken) {
                        // Update token in localStorage
                        const authData = JSON.parse(localStorage.getItem('auth') || '{}');
                        localStorage.setItem('auth', JSON.stringify({ ...authData, token: newToken }));
                      }
                    }),
                    catchError(() => {
                      // If refresh fails, logout
                      this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
                      localStorage.clear();
                      return of(null);
                    })
                  );
                } else {
                  // No user, perform logout
                  this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
                  localStorage.clear();
                  return of(null);
                }
              })
            );
          }
          return of(null);
        })
      ),
    { dispatch: false }
  );

}
