import { createAction, props } from '@ngrx/store';

export const loadConstants = createAction('[Constants] Load Constants');
export const loadConstantsSuccess = createAction(
  '[Constants] Load Constants Success',
  props<{ constants: any }>()
);
export const loadConstantsFailure = createAction(
  '[Constants] Load Constants Failure',
  props<{ error: any }>()
); 