import { createReducer, on } from '@ngrx/store';
import * as ConstantsActions from '../actions/constants.actions';

export interface ConstantsState {
  constants: {
    areaTypes: Record<string, string>;
    blacklistActions: Record<string, string>;
    cameraProviders: Record<string, string>;
    communityCategories: Record<string, string>;
    gateCategories: Record<string, string>;
    gateRoles: Record<string, string>;
    gateTypes: Record<string, string>;
    openTransactions: Record<string, string>;
    parkGranularities: Record<string, string>;
    parkingTypes: Record<string, string>;
    paymentMethods: Record<string, string>;
    whitelists: {
      public: Record<string, string>;
    };
    userTitles: Record<string, string>;
  };
  loading: boolean;
  error: any;
}

export const initialState: ConstantsState = {
  constants: {
    areaTypes: {},
    blacklistActions: {},
    cameraProviders: {},
    communityCategories: {},
    gateCategories: {},
    gateRoles: {},
    gateTypes: {},
    openTransactions: {},
    parkGranularities: {},
    parkingTypes: {},
    paymentMethods: {},
    whitelists: {
      public: {}
    },
    userTitles: {}
  },
  loading: false,
  error: null
};

export const constantsReducer = createReducer(
  initialState,
  on(ConstantsActions.loadConstants, state => ({
    ...state,
    loading: true
  })),
  on(ConstantsActions.loadConstantsSuccess, (state, { constants }) => ({
    ...state,
    constants,
    loading: false
  })),
  on(ConstantsActions.loadConstantsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  }))
); 