import { createReducer, on } from '@ngrx/store';
import { LanguageState, initialLanguageState } from 'src/app/core/models/language.model';
import * as LanguageActions from '../actions/language.actions';

export const languageReducer = createReducer(
    initialLanguageState,
    on(LanguageActions.changeLanguage, (state, { language }) => {
        return ({
            ...state,
            currentLanguage: language,
            dir: (language === 'en') ? 'ltr' : 'rtl'
        })
    })
);
