import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.store';
import { selectAuthToken, selectOrgId } from 'src/app/store/selectors/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = ''; // Replace with your API base URL
  private authToken: string | null = null;
  private orgId: string | null = null;
  constructor(private http: HttpClient, private store: Store<AppState>
  ) {
    this.baseUrl = environment.GateWayBaseURL;
    this.store.select(selectAuthToken).subscribe(token => (this.authToken = token));
    this.store.select(selectOrgId).subscribe(orgId => (this.orgId = orgId));

  }
  private createHeaders(customHeaders?: { [key: string]: string }): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authToken || ''}`, // Add the token from store
      'X-TENANT-ID': this.orgId || '',
      "Accept": "*/*"
    });

    // Append custom headers if provided
    if (customHeaders) {
      for (const key of Object.keys(customHeaders)) {
        headers = headers.set(key, customHeaders[key]);
      }
    }

    return headers;
  }

  get<T>(endpoint: string, params?: HttpParams, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers = this.createHeaders(customHeaders);
    return this.http.get<T>(`${this.baseUrl}/${endpoint}`, { headers, params });
  }

  post<T>(endpoint: string, body: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers = this.createHeaders(customHeaders);
    return this.http.post<T>(`${this.baseUrl}/${endpoint}`, body, { headers });
  }

  put<T>(endpoint: string, body: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers = this.createHeaders(customHeaders);
    return this.http.put<T>(`${this.baseUrl}/${endpoint}`, body, { headers });
  }

  patch<T>(endpoint: string, body: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers = this.createHeaders(customHeaders);
    return this.http.patch<T>(`${this.baseUrl}/${endpoint}`, body, { headers });
  }

  delete<T>(endpoint: string, customHeaders?: { [key: string]: string }, body?: any): Observable<T> {
    const headers = this.createHeaders(customHeaders);
    return this.http.delete<T>(`${this.baseUrl}/${endpoint}`, { 
      headers,
      body // Include the optional body parameter
    });
  }

  postParkingFees<T>(endpoint: string, body: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers = this.createHeaders(customHeaders);
    return this.http.post<T>(`${endpoint}`, body, { headers });
  }

  putParkingFees<T>(endpoint: string, body: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers = this.createHeaders(customHeaders);
    return this.http.put<T>(`${endpoint}`, body, { headers });
  }
}