import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api.service';
import { Pagination } from 'src/app/core/models/pagination.model';
import { SiteRegistrationRequest, SiteUpdateRequest } from 'src/app/core/models/site.model';
import { SiteLogoRequest } from 'src/app/core/models/site.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private endpoint = 'api/v1/sites/list?';

  constructor(private apiService: ApiService) { }

  // Add new method
  registerSite(siteData: SiteRegistrationRequest): Observable<any> {
    return this.apiService.post<any>('api/v1/sites/register', siteData);
  }

  updateSite(siteData: SiteUpdateRequest): Observable<any> {
    return this.apiService.post<any>('api/v1/sites/update', siteData);
  }

  getSites(data: Pagination, isRefresh: boolean = false): Observable<any> {
    // Check if sites exist in localStorage
    const storedSites = localStorage.getItem('sites');
    if (storedSites && !isRefresh) {
      try {
        const parsedSites = JSON.parse(storedSites);
        console.log(parsedSites)
        // Return sites from localStorage if available
        return of(parsedSites.sites || []);
      } catch (error) {
        console.error('Error parsing sites from localStorage', error);
      }
    }

    // If no sites in localStorage, fetch from API
    let ApiURL = this.endpoint;
    if (data.limit) ApiURL += `limit=${data.limit}`;
    if (data.skip >= 0) ApiURL += `&skip=${data.skip}`;

    return this.apiService.get<any[]>(ApiURL);
  }

  uploadSiteLogo(logoData: SiteLogoRequest): Observable<any> {
    logoData.logo = logoData.logo.replace(/^data:image\/[a-z]+;base64,/, "");
    return this.apiService.post<any>('api/v1/sites/logo/upload', logoData);
  }

  getParks(params: {
    offset?: number;
    areaType?: string;
    site?: string;
    skip?: number;
    limit?: number;
  }): Observable<any> {
    const queryParams = new HttpParams();
    if (params.offset !== undefined) queryParams.set('offset', params.offset.toString());
    if (params.areaType) queryParams.set('areaType', params.areaType);
    if (params.site) queryParams.set('site', params.site);
    if (params.skip !== undefined) queryParams.set('skip', params.skip.toString());
    if (params.limit !== undefined) queryParams.set('limit', params.limit.toString());

    return this.apiService.get<any>(`api/v1/parks/list`, queryParams);
  }

  deletePark(parkId: string): Observable<any> {
    return this.apiService.post<any>('api/v1/parks/delete', { parkId });
  } 

}