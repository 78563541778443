// store/reducers/user.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as RoleActions from '../actions/role.actions';
import { Role } from 'src/app/core/models/role.model';

export interface RolesState {
    roles: Role[];
    isLoading: boolean;
    hasError: boolean;
  }

const initialState: RolesState = {
    roles: [],
    isLoading: false,
    hasError: false
};

export const roleReducer = createReducer(
  initialState, 
  on(RoleActions.loadRolesSuccess, (state, { roles }) => ({
    ...state,
    roles,
  }))
);
