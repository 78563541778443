// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/core/services/APIs/site/site.service';
import { loadSiteSuccess, loadSiteTypeSuccess, loadSiteTypesSuccess, loadSites, loadSitesTypeSuccess } from '../actions/site.actions';
import { Store } from '@ngrx/store';
import { AddAll, transformPayload } from 'src/app/core/utils/array-utils';
import { Router } from '@angular/router';

@Injectable()
export class SiteEffects {
    skip = 0;
    limit = 250;
    constructor(private actions$: Actions,
        private store: Store,
        private siteService: SiteService,
        private router: Router) { }

    loadRoles$ = createEffect(
        () => this.actions$.pipe(
            ofType(loadSites),
            tap(() => {
                // Check localStorage first
                const storedSites = localStorage.getItem('sites');
                const storedSite = localStorage.getItem('site');
                const storedTypes = localStorage.getItem('types');
                const storedType = localStorage.getItem('type');

                if (storedSites && storedSite && storedTypes && storedType) {
                    // If all required data is in localStorage, dispatch from there
                    try {
                        const sites = JSON.parse(storedSites).sites;
                        const site = JSON.parse(storedSite).site;
                        const typesSite = JSON.parse(storedTypes).typesSite;
                        const typeSite = JSON.parse(storedType).typeSite;

                        this.store.dispatch(loadSitesTypeSuccess({ sites: AddAll(sites) }));
                        this.store.dispatch(loadSiteSuccess({ site }));
                        this.store.dispatch(loadSiteTypesSuccess({ typesSite }));
                        this.store.dispatch(loadSiteTypeSuccess({ typeSite }));

                        return;
                    } catch (error) {
                        console.error('Error parsing stored data', error);
                    }
                }

                // If not in localStorage or parsing failed, fetch from API
                this.siteService.getSites({ limit: this.limit, skip: this.skip })
                    .pipe(
                        map((sites: any) => (sites?.data || sites)), 
                        catchError(() => of({ type: '[Site] Load Sites Failed' }))
                    )
                    .subscribe((sites: any) => {
                        this.store.dispatch(loadSitesTypeSuccess({ sites: AddAll(sites) }));
                    });
            })
        ),
        { dispatch: false }
    );
    persistSites$ = createEffect(()=>
        this.actions$.pipe(
            ofType(loadSitesTypeSuccess),
            tap(({sites})=>{
                localStorage.setItem('sites', JSON.stringify({ sites }));
                this.store.dispatch(loadSiteSuccess({ site: sites[0] })); 
            })
        ),
        { dispatch: false }
    )
    persistSite$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSiteSuccess),
                tap(({ site }) => { 
                    localStorage.setItem('site', JSON.stringify({ site })); 
                    
                    // If siteId is 'all', clear type storage and navigate to organizations
                    if (site.siteId === 'all') {
                        localStorage.removeItem('types');
                        localStorage.removeItem('type');
                        
                        // Convert current URL from access-control to organizations 
                        this.router.navigate(['/dashboard/organizations']);
                        return;
                    } 
                    let arrayOfTypes = transformPayload(site.registeredServices);
                    this.store.dispatch(loadSiteTypesSuccess({typesSite:arrayOfTypes}))
                })
            ),
        { dispatch: false }
    );
    persistTypes$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSiteTypesSuccess),
                tap(({ typesSite}) => {
                    localStorage.setItem('types', JSON.stringify({ typesSite })); 
                    
                    // Check if type is already in local storage
                    const storedType = localStorage.getItem('type');
                    const parsedStoredType = storedType ? JSON.parse(storedType).typeSite : null;
                    
                    if (!storedType || !typesSite.includes(parsedStoredType)) {
                        // Dispatch first type if there's no stored type or if stored type isn't in typesSite
                        this.store.dispatch(loadSiteTypeSuccess({typeSite: typesSite[0]}))
                    }
                })
            ),
        { dispatch: false }
    );
    persistType$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSiteTypeSuccess),
                tap(({ typeSite}) => {
                    localStorage.setItem('type', JSON.stringify({ typeSite })); 
                })
            ),
        { dispatch: false }
    );
}
