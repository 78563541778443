 export const environment = {
  production: false,
  apiURL: 'https://dev.api.parking.ldcs.ae/api',
  GateWayBaseURL: 'https://dev.api.parking.ldcs.ae',
  googleMapsApiKey: 'AIzaSyBu1cpRbk3NflsJ7Aiy_9afYUpMrwXCrZo',
  reportsBaseURL: 'https://dev.tenant-management.parking.ldcs.ae/api/v1',
  parkingFeesBaseURL: 'https://dev.api.parking.ldcs.ae',
  auth: {
    domain: 'dev-5dz3e7c0.us.auth0.com',
    clientId: '7y0tJJ8VVzsBQhIU3upOBGIMGckkKIJW',
    useRefreshTokens: true,
  },
  audience: 'https://my-test-api/',
}; 