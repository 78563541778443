 import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { ArrayResponseApi } from 'src/app/core/models/api-query.model';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private userRoleEndpoint = 'organization/users/roles';
  private roleEndpoint = 'role';

  constructor(private apiService: ApiService) {}

  getUserRole(data: { orgId: string; userId: string}): Observable<any[]> {
    return this.apiService.post<any[]>(this.userRoleEndpoint,data);
  } 

  getRoles(): Observable<any> {
    return this.apiService.get<any>(this.roleEndpoint);
  }
}
