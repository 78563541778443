import { Site } from "../models/site.model";

// core/utils/array-utils.ts
export function removeDuplicates<T>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}

export function sortArrayByKey<T>(arr: T[], key: keyof T, ascending = true): T[] {
    return arr.sort((a, b) =>
        ascending
            ? a[key] > b[key]
                ? 1
                : -1
            : a[key] < b[key]
                ? 1
                : -1
    );
}

export function transformPayload(payload: Record<string, any>): string[] {
    return Object.entries(payload)
        .filter(([key, value]) => key !== 'logoUrl' && value === true) // Filter out 'logoUrl' and keep only keys with `true` values
        .map(([key]) => key) // Extract the keys
}
export function AddAll(payload: Site[]): Site[] {
    // Check if "All" site already exists
    const allSiteExists = payload.some(site => site.siteId === "all");
    
    // Only add if it doesn't exist
    if (!allSiteExists) {
        const site: Site = {
            siteId: "all",
            code: "",
            name: "All",
            parkingType: "",
            createdAt: "",
            updatedAt: "",
            entrancesCount: 0,
            exitsCount: 0,
            long: "",
            lat: "",
            logoUrl: "",
            registeredServices: {
                public: false,
                vip: false,
                gym: false,
                reserved: false,
                openReserved: false,
                community: false
            },
            blacklistConfig: {
                action: ""
            },
            workingHours: {
                active: false,
                start: null,
                end: null
            },
            gymAreaConfig: {
                hoursPerVisit: null,
                FreeVisitsCount: null
            },
            notifications: {
                gatesActivity: {
                    active: false,
                    emails: []
                },
                cashierShiftReport: {
                    active: false,
                    emails: []
                },
                blacklistedCarsReport: {
                    active: false,
                    emails: []
                }
            }
        }
        payload.push(site);
    }
    return payload;
}
