// store/actions/Site.actions.ts
import { createAction, props } from '@ngrx/store';
import { Site} from 'src/app/core/models/site.model';


export const loadSite = createAction('[Site] Load Site Types');
export const loadSites = createAction('[Site] Load Site Types');
export const loadSiteTypes = createAction('[Site] Load Site Types');
export const loadSiteType = createAction('[Site] Load Site Type');
export const loadSitesTypeSuccess = createAction(
  '[Site] Load Sites  Success',
  props<{ sites: Site[] }>()
);
export const loadSiteTypeSuccess = createAction(
  '[Site] Load Type Success',
  props<{ typeSite: string }>()
);
export const loadSiteTypesSuccess = createAction(
  '[Site] Load Types Success',
  props<{ typesSite: string[] }>()
);
export const loadSiteSuccess = createAction(
  '[Site] Load Site Success',
  props<{ site: Site }>()
);
