import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
 import { TranslateService } from '@ngx-translate/core';
 import { isPlatformBrowser } from '@angular/common';
 import { AppState } from 'src/app/store/app.store';
 import { changeLanguage } from 'src/app/store/actions/language.actions';
 import { Store } from '@ngrx/store';

 @Injectable({
   providedIn: 'root'
 })

 export class TranslationService {
   defaultLang = 'en';

   constructor(
     private translateService: TranslateService,
     @Inject(PLATFORM_ID) private platformId: Object,
     private store: Store<AppState>

   ) {
     if (isPlatformBrowser(this.platformId)) {
       const savedLang = localStorage.getItem('lng');
       if (savedLang) {
         this.defaultLang = savedLang;
       }
       this.translateService.setDefaultLang(this.defaultLang);
       this.translateService.use(this.defaultLang);
     }
   }

   changeLang(lang: string) {
     this.translateService.use(lang);
     const direction = lang === 'ar' ? 'rtl' : 'ltr';

     if (isPlatformBrowser(this.platformId)) {
       localStorage.setItem('lng', lang);
       localStorage.setItem('dir', direction);
       this.store.dispatch(changeLanguage({language:lang,dir:direction}));
     }
   }
 }