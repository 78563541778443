export const isOrganizationApi = (url: string): boolean => {
  return url.includes('/organizations/') || 
         url.includes('/organization/');
};

export const extractSiteParam = (url: string, params?: any): string | null => {
  // Check URL parameters
  const urlParams = new URLSearchParams(url.split('?')[1]);
  const siteFromUrl = urlParams.get('site');
  
  // Check query parameters
  const siteFromParams = params?.get?.('site') || 
                        params?.site || 
                        null;
                        
  return siteFromUrl || siteFromParams;
}; 