import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private endpoint = 'api/v1/organizations/services?';
  private constants = 'api/v1/sites/services?';

  constructor(private apiService: ApiService) {}

  getStatistics(): Observable<any[]> {
    return this.apiService.get<any[]>(this.endpoint);
  } 
  getConstants(): Observable<any[]> {
    return this.apiService.get<any[]>(this.constants);
  } 
}