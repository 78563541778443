// auth.model.ts
export interface AuthState {
  user: any | null; // Replace with your Auth0 user model
  token: string | null;
  isAuthenticated: boolean;
  error?:string | null
}

export const initialAuthState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  error:null

};
